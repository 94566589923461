import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppRomana = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="TU AI O VOCE! Noi te putem ajută să-ți faci vocea auzită – aici e acasă pentru noi toți! Trimite acest mesaj către prietenii tăi europeni din UK."
      description="Click pe acest link și trimite un mesaj către prietenii tăi pe WhatsApp - dă-le o voce! ➡️"
      shareImage="romanian"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey! Ești un cetățean român care locuiește în UK?
Avem dreptul să votăm la alegerile europarlamentare în UK!

💥Trimite acest mesaj către prietenii tăi din UK - aici e acasă pentru noi toți!💥
Click aici: https://saveourfuture.uk/romana

*Înregistrează-te să votezi până pe 7 mai*
Urmează pașii simpli aici:
https://saveourfuture.uk/simple
(Fă-o acum!)

*IMPORTANT !!!*
💥 Trimite acest mesaj către prietenii tăi europeni din UK - aici e acasă pentru noi toți! 💥
Click aici: https://saveourfuture.uk/romana

🗣 *DE DATĂ ASTA VOCEA NOASTRĂ CONTEAZĂ!!!* 🗣`}
    />
    <Interstitial>
      <p>Alegeți cinci prieteni sau grupuri de top pentru a trimite mesajul Whatsapp</p>
      <p>Generați mesajul acum</p>
      <p>Puteți personaliza mesajul în pasul următor ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppRomana.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppRomana;

export const query = graphql`
  query RomanaQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
